export const classifyType = {
    excel: ['表格', 'xls', 'xlsx', 'et', 'ett', 'xlt'], // excel
    ppt: ['ppt', 'pptx', 'wpt', 'pps', 'dps', 'dpt', 'pot'], // ppt
    word: ['word', 'doc', 'docx', 'wps', 'dot'], // word
    doc: ['文档', 'txt', 'mp3', 'wma', 'mmf', 'amr', 'aac', 'flac'], // 文档
    zip: ['zip'], // zip
    rar: ['rar'], // rar
    pdf: ['pdf'], // pdf
    radio: ['音频', 'wav', 'mp3', 'wma', 'mmf', 'amr', 'aac', 'flac'], // 音频
    video: ['视频', 'wmv', 'asf', 'rm', 'rmvb', 'mov', 'mp4', 'avi', 'mpg', 'mpeg'], // 视频
    img: ['图片', 'png', 'jpg', 'jpeg', 'bmp', 'pic', 'tif', 'jpe', 'svg', 'tiff', 'ico'], // 图片
}