const baseUrl = window.__POWERED_BY_QIANKUN__ ? '/main/app/app-ydwp' : '';
export const routes = [
  { path: baseUrl + "/", redirect: baseUrl + '/space' },
  { path: baseUrl + "/space", component: () => import("@/pages/space.vue"), meta: {page: 1} },
  { path: baseUrl + "/group", component: () => import("@/pages/group.vue"), meta: {page: 2} },
  { path: baseUrl + "/groupFile", component: () => import("@/pages/groupFile.vue"), meta: {page: 2} },
  { path: baseUrl + "/collect", component: () => import("@/pages/collect.vue"), meta: {page: 3} },
  { path: baseUrl + "/recycle", component: () => import("@/pages/recycle.vue"), meta: {page: 4} },
  { path: baseUrl + "/search", component: () => import("@/pages/search.vue"), meta: {page: 1} },
  { path: baseUrl + "/userList", component: () => import("@/pages/userList.vue"), meta: {page: 2} },
];