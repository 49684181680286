import { classifyType } from "@/utils/type";
import { getPcMacAddress, getSliceBySection } from "@/utils/FileUtils";
import { getFileTransferList, checkUploadFile, uploadChunk, uploadMergeChunk, optFileTransfer, uploadChunk2 } from "@/api/space";
export default {
  changeUpList(state, val) {
    state.uploadList = val;
  },
  async addUploadList(state, val) {
    const uuid = getPcMacAddress();
    const isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    const name = val[0].file_path.substring(
      val[0].file_path.lastIndexOf(isMac ? "/" : "\\") + 1
    );
    const params = {
      appUuid: uuid,
      fileName: name.substring(0, name.lastIndexOf(".")),
      fileSuffix: name.substring(name.lastIndexOf(".") + 1),
      folderId: val[1],
      groupId: val[2] ? val[2] : null,
      md5: val[0].file_hash,
      totalSize: val[0].file_size,
    };

    console.log("上传文件第一步参数：", params);

    const res = await checkUploadFile(params);
    if (res) {
      const fileFolder = {
        name: params.fileName,
        fileName: params.fileName + "." + params.fileSuffix,
        size: params.totalSize, // 单位字节
        fileSuffix: params.fileSuffix, // 文件后缀
        downStreamToken: res.data.fileId,
        start: 0,
        end: 0,
        filePath: val[0].file_path,
        chunkTotal: Math.ceil(params.totalSize / this.state.sectionSize),
        currentChunkIndex: 0,
        mdFive: val[0].file_hash,
        folderId: val[1],
        groupId: val[2] ? val[2] : null,
        transferStatus: 1,
      };
      await uploadChunk2({
        appUuid: uuid,
        chunkTotal: fileFolder.chunkTotal,
        currentChunkIndex: 0,
        fileId: fileFolder.downStreamToken,
        fileName: fileFolder.name,
        fileSuffix: fileFolder.fileSuffix,
        mdFive: fileFolder.mdFive,
        totalSize: fileFolder.size,
        groupId: fileFolder.groupId ? fileFolder.groupId : null,
        folderId: fileFolder.folderId ? fileFolder.folderId : null,
        filePath: fileFolder.filePath,
        chunkEndByte: 0,
        chunkStartByte: 0,
        currentChunkSize: 0,
      });
      const res2 = await getFileTransferList({
        operation: 1,
        appUuid: getPcMacAddress()
      });
      let addTaskId = null;
      if (res2) {
        const list = JSON.parse(JSON.stringify(state.uploadList));
        res2.data.map((item) => {
          let change = true;
          list.forEach((key) => {
            if (key.taskId === item.id) {
              change = false;
            }
          });
          if (change) {
            const fileFolder = {};
            Object.keys(classifyType).forEach((key) => {
              if (classifyType[key].includes(item.fileSuffix.toLowerCase())) {
                fileFolder.icon = key;
              }
            });
            if (!fileFolder.icon) {
              fileFolder.icon = "other";
            }
            fileFolder.checked = false;
            fileFolder.type = 1;
            fileFolder.start = item.fileChunkStartByte
              ? item.fileChunkStartByte
              : 0;
            fileFolder.downStreamToken = item.dowToken;
            fileFolder.id = item.fileId;
            fileFolder.taskId = item.id;
            addTaskId = item.id;
            fileFolder.name = item.fileName;
            fileFolder.fileName = item.fileName + "." + item.fileSuffix;
            fileFolder.fileSuffix = item.fileSuffix;
            fileFolder.downloaded = item.fileChunkEndByte
              ? item.fileChunkEndByte
              : 0;
            fileFolder.transferStatus = item.status;
            fileFolder.fileBytesList = [];
            fileFolder.size = item.totalSize;
            fileFolder.filePath = item.filePath;
            fileFolder.folderId = item.folderId;
            fileFolder.groupId = item.groupId;
            fileFolder.mdFive = item.mdFive;
            fileFolder.chunkTotal = item.totalChunkTotal;
            fileFolder.currentChunkIndex = item.currentChunkIndex;
            fileFolder.transfer = item.fileChunkEndByte
              ? item.fileChunkEndByte
              : 0;
            list.unshift(fileFolder);
          }
        });
        state.uploadList = list;
        this.commit("showToast", ["文件已添加至传输列表", "", () => {}]);
        if (addTaskId) {
          this.commit("uploadPlay", [addTaskId]);
        }
      }
    }
  },
  async getUpLoadList(state) {
    // 获取上传列表
    getFileTransferList({ operation: 1, appUuid: getPcMacAddress() }).then(
      (res) => {
        if (res) {
          const list = [];
          res.data.map((item) => {
            const fileFolder = {};
            Object.keys(classifyType).forEach((key) => {
              if (classifyType[key].includes(item.fileSuffix.toLowerCase())) {
                fileFolder.icon = key;
              }
            });
            if (!fileFolder.icon) {
              fileFolder.icon = "other";
            }
            fileFolder.checked = false;
            fileFolder.type = 1;
            fileFolder.start = item.fileChunkStartByte
              ? item.fileChunkStartByte
              : 0;
            fileFolder.downStreamToken = item.dowToken;
            fileFolder.id = item.fileId;
            fileFolder.taskId = item.id;
            fileFolder.name = item.fileName;
            fileFolder.fileName = item.fileName + "." + item.fileSuffix;
            fileFolder.fileSuffix = item.fileSuffix;
            fileFolder.downloaded = item.fileChunkEndByte
              ? item.fileChunkEndByte
              : 0;
            fileFolder.transferStatus = item.status;
            fileFolder.fileBytesList = [];
            fileFolder.size = item.totalSize;
            fileFolder.filePath = item.filePath;
            fileFolder.folderId = item.folderId;
            fileFolder.groupId = item.groupId;
            fileFolder.mdFive = item.mdFive;
            fileFolder.chunkTotal = item.totalChunkTotal;
            fileFolder.currentChunkIndex = item.currentChunkIndex;
            fileFolder.transfer = item.fileChunkEndByte
              ? item.fileChunkEndByte
              : 0;
            list.push(fileFolder);
          });
          state.uploadList = list;
          state.uploadPlayList = list.filter(
            (item) => item.transferStatus === 1
          );
          state.uploadWaitList = list.filter(
            (item) => item.transferStatus === 7
          );
          this.commit("startUpload");
          this.commit("getUploadStatus");
        }
      }
    );
  },
  getUploadStatus(state) {
    // 获取下载中和等待中的状态
    state.uploadPlayList = state.uploadList.filter(
      (item) => item.transferStatus === 1
    );
    state.uploadWaitList = state.uploadList.filter(
      (item) => item.transferStatus === 7
    );
    this.commit("changeUploadStatus");
  },
  changeUploadStatus(state) {
    if (
      state.downloadPlayList.length < state.syncNum &&
      state.uploadWaitList.length > 0
    ) {
      this.commit(
        "uploadPlay",
        state.uploadWaitList.map((key) => key.taskId)
      );
    }
  },
  startUpload(state) {
    // 开始下载
    state.uploadPlayList.map((key) => {
      this.commit("uploadIng", key);
    });
  },
  changeUploadProgress(state, val) {
    let reduce = val[0].reduce((v1, v2) => v1 + v2);
    const list = JSON.parse(JSON.stringify(state.uploadList));
    list.forEach((item) => {
      if (item.taskId === val[1].taskId && item.transferStatus === 1) {
        if (
          item.downloaded + reduce > item.transfer &&
          item.downloaded + reduce <= val[1].size
        ) {
          item.transfer = item.downloaded + reduce;
        }
      }
    });
    state.uploadList = list;
  },
  changeUploaded(state, val) {
    const list = JSON.parse(JSON.stringify(state.uploadList));
    let uploadObj = null;
    list.forEach((item) => {
      if (item.taskId === val[0].taskId) {
        item.start = val[1];
        item.downloaded = val[2];
        if (item.transferStatus === 1) {
          item.transfer = val[2];
          item.transferStatus = val[3];
          item.currentChunkIndex = val[4];
        }
        uploadObj = item;
      }
    });
    state.uploadList = list;
    this.commit("getUploadStatus");
    if (val[3] === 1 && uploadObj && uploadObj.transferStatus === 1) {
      this.commit("uploadIng", uploadObj);
    }
  },
  async uploadIng(state, fileFolder) {
    const uuid = getPcMacAddress();
    if (fileFolder.transferStatus != 1) {
      return;
    }
    if (fileFolder.downloaded >= fileFolder.size) {
      await uploadMergeChunk({
        appUuid: uuid,
        fileId: fileFolder.downStreamToken,
        fileName: fileFolder.name,
        fileSuffix: fileFolder.fileSuffix,
        filePath: fileFolder.filePath,
        mdFive: fileFolder.mdFive,
        groupId: fileFolder.groupId ? fileFolder.groupId : null,
        folderId: fileFolder.folderId ? fileFolder.folderId : null,
        totalSize: fileFolder.size,
      });
      this.commit("changeListUpdate")
      this.commit("changeUploaded", [fileFolder, fileFolder.start, fileFolder.downloaded, 2, fileFolder.currentChunkIndex]);
      return;
    }

    const start = fileFolder.downloaded;
    const end =
      parseInt(fileFolder.downloaded) + this.state.sectionSize >= fileFolder.size
        ? fileFolder.size
        : parseInt(fileFolder.downloaded) + this.state.sectionSize;
    const currentChunkIndex = fileFolder.downloaded === 0 ? 0
      : fileFolder.currentChunkIndex + 1;
    const promiseAll = [];
    var i,
      ln = this.state.thread;

    for (i = 0; i < ln; i++) {
      const startSlice = start + this.state.sectionSize * i;
      const endSlice = end + this.state.sectionSize * i;
      if (endSlice >= fileFolder.size) {
        promiseAll.push(
          new Promise((resolve) => {
            getSliceBySection(
              fileFolder.filePath,
              startSlice,
              fileFolder.size
            )
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                console.log(err);
              });
          })
        );
        break;
      } else {
        promiseAll.push(
          new Promise((resolve) => {
            getSliceBySection(fileFolder.filePath, startSlice, endSlice)
              .then((res) => {
                resolve(res);
              })
              .catch((err) => {
                console.log(err);
              });
          })
        );
      }
    }

    const promiseRes = await Promise.all(promiseAll);
    const promiseAll2 = [];
    const progressList = new Array(promiseRes.length);
    let maxStart = 0;
    let maxEnd = 0;
    let maxCurrentChunkIndex = 0;
    promiseRes.forEach((item, idx) => {
      const startSlice = start + this.state.sectionSize * idx;
      const endSlice =
        end + this.state.sectionSize * idx >= fileFolder.size
          ? fileFolder.size
          : end + this.state.sectionSize * idx;
      maxStart = startSlice;
      maxEnd = endSlice;
      maxCurrentChunkIndex = currentChunkIndex + idx
      promiseAll2.push(
        new Promise((resolve) => {
          uploadChunk(
            {
              appUuid: uuid,
              chunkTotal: fileFolder.chunkTotal,
              currentChunkIndex: currentChunkIndex + idx,
              fileId: fileFolder.downStreamToken,
              fileName: fileFolder.name,
              fileSuffix: fileFolder.fileSuffix,
              mdFive: fileFolder.mdFive,
              totalSize: fileFolder.size,
              groupId: fileFolder.groupId ? fileFolder.groupId : null,
              folderId: fileFolder.folderId ? fileFolder.folderId : null,
              filePath: fileFolder.filePath,
              chunkEndByte: endSlice,
              chunkStartByte: startSlice,
              file: item.base64,
              chunkMdFive: item.hash,
              currentChunkSize: endSlice - startSlice,
            },
            (progress) => {
              progressList[idx] = progress.loaded * 0.5;
              this.commit("changeUploadProgress", [progressList, fileFolder]);
            }
          ).then((res) => {
            resolve(res);
          });
        })
      );
    });
    const promiseAll2Res = await Promise.all(promiseAll2);
    let nextTo = true
    promiseAll2Res.map(resP2 => {
      if (!resP2) {
        nextTo = false
      }
    })
    if (nextTo) {
      this.commit("changeUploaded", [fileFolder, maxStart, maxEnd, 1, maxCurrentChunkIndex]);
    } else {
      this.commit("changeUploaded", [fileFolder, fileFolder.start, fileFolder.downloaded, 1, fileFolder.currentChunkIndex]);
    }
  },
  uploadPlay(state, ids) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of ids) {
      const isStart = state.downloadPlayList.length < state.syncNum;
      const uploadList = JSON.parse(JSON.stringify(state.uploadList));
      uploadList.forEach((key) => {
        if (key.taskId === taskId) {
          if (key.downloaded >= key.size) {
            key.transfer = key.size;
            key.transferStatus = 2;
          } else {
            key.transferStatus = isStart ? 1 : 7;
            let change = true;
            state.uploadPlayList.map(key2 => {
              if (key2.taskId === taskId) {
                change = false
              }
            })
            if (isStart && change) {
              this.commit("uploadIng", key);
              state.uploadPlayList.push(key)
            }
            optFileTransfer({
              fileTransferId: taskId,
              optStatus: isStart ? 2 : 5,
            });
          }
        }
      });
      state.uploadList = uploadList;
    }
  },
  uploadPause(state, list) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of list) {
      const uploadList = JSON.parse(JSON.stringify(state.uploadList));
      uploadList.forEach((key) => {
        if (key.taskId === taskId) {
          key.transferStatus = 4;
        }
      });
      state.uploadList = uploadList;
      optFileTransfer({
        fileTransferId: taskId,
        optStatus: 1,
      });
    }
  },
  uploadCancel(state, list) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of list) {
      const uploadList = JSON.parse(JSON.stringify(state.uploadList));
      uploadList.splice(
        uploadList.findIndex((key) => key.taskId === taskId),
        1
      );
      state.uploadList = uploadList;
      optFileTransfer({
        fileTransferId: taskId,
        optStatus: 4,
      });
    }
  },
};
