import { classifyType } from "@/utils/type";
import { getPcMacAddress, saveSlice, mergeFile } from "@/utils/FileUtils";
import {
  getFileTransferList,
  optFileTransfer,
  downloadTwo,
  downloadSuccess,
  upFileDowSchedule
} from "@/api/space";
export default {
  changeDownList(state, val) {
    state.downloadList = val;
  },
  async addDownloadList(state) {
    console.log(1233)
    // 获取下载列表
    const res = await getFileTransferList({
      operation: 2,
      appUuid: getPcMacAddress(),
    });
    const addTaskIds = [];
    if (res) {
      const list = JSON.parse(JSON.stringify(state.downloadList));
      res.data.map((item) => {
        let change = true;
        list.forEach((key) => {
          if (key.taskId === item.id) {
            change = false;
          }
        });
        
        if (change) {
          let fileFolder = {};
          Object.keys(classifyType).forEach((key) => {
            if (classifyType[key].includes(item?.fileSuffix?.toLowerCase())) {
              fileFolder.icon = key;
            }
          });
          if (!fileFolder.icon) {
            fileFolder.icon = "other";
          }
          fileFolder.checked = false;
          fileFolder.pcDowUrl = item.pcDowUrl;
          fileFolder.type = 1;
          fileFolder.start = item.fileChunkStartByte
            ? item.fileChunkStartByte
            : 0;
          fileFolder.downStreamToken = item.dowToken;
          fileFolder.id = item.fileId;
          fileFolder.taskId = item.id;
          addTaskIds.push(item.id)
          fileFolder.name = item.fileName;
          fileFolder.fileName = item.fileName + "." + item.fileSuffix;
          fileFolder.fileSuffix = item.fileSuffix;
          fileFolder.downloaded = item.fileChunkEndByte
            ? item.fileChunkEndByte
            : 0;
          fileFolder.mdFive = item.mdFive;
          fileFolder.transferStatus = item.status;
          fileFolder.fileBytesList = [];
          fileFolder.size = item.totalSize;
          fileFolder.transfer = item.fileChunkEndByte
            ? item.fileChunkEndByte
            : 0;
          list.unshift(fileFolder);
        }
      });
      state.downloadList = list;
      if (addTaskIds.length > 0) {
        this.commit("downloadPlay", addTaskIds);
      }
    }
  },
  async getDownLoadList(state) {
    // 获取下载列表
    const res = await getFileTransferList({
      operation: 2,
      appUuid: getPcMacAddress(),
    });
    if (res) {
      const list = [];
      res.data.map((item) => {
        let fileFolder = {};
        Object.keys(classifyType).forEach((key) => {
          if (classifyType[key].includes(item?.fileSuffix?.toLowerCase())) {
            fileFolder.icon = key;
          }
        });
        if (!fileFolder.icon) {
          fileFolder.icon = "other";
        }
        fileFolder.checked = false;
        fileFolder.pcDowUrl = item.pcDowUrl;
        fileFolder.type = 1;
        fileFolder.start = item.fileChunkStartByte
          ? item.fileChunkStartByte
          : 0;
        fileFolder.downStreamToken = item.dowToken;
        fileFolder.id = item.fileId;
        fileFolder.taskId = item.id;
        fileFolder.name = item.fileName;
        fileFolder.fileName = item.fileName + "." + item.fileSuffix;
        fileFolder.fileSuffix = item.fileSuffix;
        fileFolder.downloaded = item.fileChunkEndByte
          ? item.fileChunkEndByte
          : 0;
        fileFolder.mdFive = item.mdFive;
        fileFolder.transferStatus = item.status;
        fileFolder.fileBytesList = [];
        fileFolder.size = item.totalSize;
        fileFolder.transfer = item.fileChunkEndByte ? item.fileChunkEndByte : 0;
        list.push(fileFolder);
      });
      state.downloadList = list;
      this.commit("getDownloadStatus");
      this.commit("startDownload");
    }
  },
  getDownloadStatus(state) {
    // 获取下载中和等待中的状态
    state.downloadPlayList = state.downloadList.filter(
      (item) => item.transferStatus === 1
    );
    state.downloadWaitList = state.downloadList.filter(
      (item) => item.transferStatus === 7
    );
    this.commit("changeDownloadStatus");
  },
  changeDownloadStatus(state) {
    if (
      state.downloadPlayList.length < state.syncNum &&
      state.downloadWaitList.length > 0
    ) {
      this.commit(
        "downloadPlay",
        state.downloadWaitList.map((key) => key.taskId)
      );
    }
  },
  startDownload(state) {
    // 开始下载
    state.downloadPlayList.map((key) => {
      this.commit("downloadIng", key);
    });
  },
  changeDownloadProgress(state, val) {
    let reduce = val[0].reduce((v1, v2) => v1 + v2);
    const list = JSON.parse(JSON.stringify(state.downloadList));
    list.forEach((item) => {
      if (item.taskId === val[1].taskId && item.transferStatus === 1) {
        if (
          item.downloaded + reduce > item.transfer &&
          item.downloaded + reduce <= val[1].size
        ) {
          item.transfer = item.downloaded + reduce;
        }
      }
    });
    state.downloadList = list;
  },
  changeDownloaded(state, val) {
    const list = JSON.parse(JSON.stringify(state.downloadList));
    let downloadObj = null;
    list.forEach((item) => {
      if (item.taskId === val[0].taskId) {
        item.start = val[1];
        item.downloaded = val[2];
        if (item.transferStatus === 1) {
          item.transfer = val[2];
          item.transferStatus = val[3];
        }
        downloadObj = item;
      }
    });
    state.downloadList = list;
    this.commit("getDownloadStatus");
    if (val[3] === 1 && downloadObj && downloadObj.transferStatus === 1) {
      this.commit("downloadIng", downloadObj);
    }
  },
  async downloadIng(state, fileFolder) {
    fileFolder.start = fileFolder.start ? fileFolder.start : 0;
    fileFolder.downloaded = fileFolder.downloaded ? fileFolder.downloaded : 0;
    if (fileFolder.downloaded >= fileFolder.size) {
      const res3 = await mergeFile(
        fileFolder.pcDowUrl,
        fileFolder.mdFive,
        fileFolder.fileName
      );
      if (res3.status) {
        this.commit("changeDownloaded", [fileFolder, fileFolder.start, fileFolder.downloaded, 2]);
        await downloadSuccess({
          dowToken: fileFolder.downStreamToken,
        });
        this.commit("changeListUpdate")
        console.log("合并切片结果：", res3);
      } else {
        this.commit("showToast", [res3.msg, "", () => {}]);
        this.commit("downloadCancel", [fileFolder.taskId]);
      }
      return;
    }
    const start = fileFolder.downloaded;
    const end =
      parseInt(fileFolder.downloaded) + state.sectionSize >= fileFolder.size
        ? fileFolder.size
        : parseInt(fileFolder.downloaded) + state.sectionSize;
    const promiseAll = [];
    let maxStart = 0;
    let maxEnd = 0;
    var i,
      ln = this.state.thread;
    const progressList = new Array(this.state.thread);
    for (i = 0; i < ln; i++) {
      const idx = i;
      const startSlice = start + this.state.sectionSize * i;
      const endSlice = end + this.state.sectionSize * i;
      if (endSlice >= fileFolder.size) {
        promiseAll.push(
          new Promise((resolve) => {
            downloadTwo(
              {
                downStreamToken: fileFolder.downStreamToken,
                fileChunkStartByte: startSlice,
                fileChunkEndByte: fileFolder.size,
                end: true,
                totalChunkSize: Math.ceil(
                  fileFolder.size / this.state.sectionSize
                ),
              },
              (progress) => {
                progressList[idx] = progress.loaded * 0.5;
                this.commit("changeDownloadProgress", [
                  progressList,
                  fileFolder,
                ]);
              }
            ).then((res) => {
              resolve(res);
            });
          })
        );
        maxStart = startSlice;
        maxEnd = fileFolder.size;
        break;
      } else {
        promiseAll.push(
          new Promise((resolve) => {
            downloadTwo(
              {
                downStreamToken: fileFolder.downStreamToken,
                fileChunkStartByte: startSlice,
                fileChunkEndByte: endSlice,
                end: false,
                totalChunkSize: Math.ceil(
                  fileFolder.size / this.state.sectionSize
                ),
              },
              (progress) => {
                progressList[idx] = progress.loaded * 0.5;
                this.commit("changeDownloadProgress", [
                  progressList,
                  fileFolder,
                ]);
              }
            ).then((res) => {
              resolve(res);
            });
          })
        );
        maxEnd = endSlice;
        maxStart = startSlice;
      }
    }

    const promiseRes = await Promise.all(promiseAll);
    const promiseAll2 = [];
    promiseRes.forEach((item) => {
      promiseAll2.push(
        new Promise((resolve) => {
          saveSlice({
            file_name: item.data.chunkName,
            slice_save_path: fileFolder.pcDowUrl,
            file_hash: item.data.fileMdFive,
            file_slice_hash: item.data.md5,
            slice_stream: item.data.fileBytes,
          }).then((res) => {
            resolve(res);
          });
          upFileDowSchedule({
            transId: fileFolder.taskId,
            fileChunkStartByte: maxStart,
            fileChunkEndByte: maxEnd
          });
        })
      );
    });

    await Promise.all(promiseAll2);
    this.commit("changeDownloaded", [fileFolder, maxStart, maxEnd, 1]);
  },
  downloadPlay(state, ids) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of ids) {
      const isStart = state.downloadPlayList.length < state.syncNum;
      const downloadList = JSON.parse(JSON.stringify(state.downloadList));
      downloadList.forEach((key) => {
        if (key.taskId === taskId) {
          if (key.downloaded >= key.size) {
            key.transfer = key.size;
            key.transferStatus = 2;
          } else {
            key.transferStatus = isStart ? 1 : 7;
            let change = true;
            console.log('state.downloadPlayList',state.downloadPlayList)
            state.downloadPlayList.map(key2 => {
              if (key2.taskId === taskId) {
                change = false
              }
            })
            if (isStart && change) {
              this.commit("downloadIng", key);
              state.downloadPlayList.push(key)
            }
            optFileTransfer({
              fileTransferId: taskId,
              optStatus: isStart ? 2 : 5,
            });
          }
        }
      });
      state.downloadList = downloadList;
    }
  },
  downloadPause(state, list) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of list) {
      const downloadList = JSON.parse(JSON.stringify(state.downloadList));
      downloadList.forEach((key) => {
        if (key.taskId === taskId) {
          key.transferStatus = 4;
        }
      });
      state.downloadList = downloadList;
      optFileTransfer({
        fileTransferId: taskId,
        optStatus: 1,
      });
    }
  },
  downloadCancel(state, list) {
    if (!state.transferLine) {
      this.commit("showToast", ['网络连接已断开，请检查网络', "", () => {}]);
      return
    }
    for (const taskId of list) {
      const downloadList = JSON.parse(JSON.stringify(state.downloadList));
      downloadList.splice(
        downloadList.findIndex((key) => key.taskId === taskId),
        1
      );
      state.downloadList = downloadList;
      optFileTransfer({
        fileTransferId: taskId,
        optStatus: 4,
      });
    }
  },
};
