import request from './request'
import store from "@/store"
const base = 'http://172.104.125.7/cloud/data'
// const base = 'http://192.168.56.27:9900'
// const baseUpd = 'http://192.168.56.27:9900'
const baseUpd = 'http://172.104.125.7/cloud/data'

// window.console.log = function (){};
console.log('版本号：1.2.5')
export const getList = (data) => { // 空间列表
    if (data.isFolder) {
        const params = {
            type: 1,
            order: data.order,
            folderId: data.folderId,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/getFileForParentFolder`,
            params: params
        })
    } else {
        const params = {
            order: data.order,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/indexFileListForSpace`,
            params: params
        })
    }
}

export const checkMoaToken = (data) => { // 检查token
    return request({
        method: 'post',
        url: baseUpd + `/cs/user/api/user/checkMoaToken`,
        data: data
    })
}

export const downloadSuccess = (data) => {
    return request({
        method: 'post',
        url: base + '/cs/file/api/download/success',
        data: data
    })
}

export const getFolderRootId = (data) => { // 获取用户根目录id
    return request({
        method: 'get',
        url: base + `/cs/file/api/getLoginUserRootFolderId`,
        params: data
    })
}

export const postCreateFolder = (data) => { // 创建文件夹
    if (data.groupId != '0') {
        store.commit('updateOptCode', ['createGroupFolderCode', data.groupId, ''])
    }
    return request({
        method: 'post',
        url: base + `/cs/file/api/createFolder`,
        data: data
    })
}

export const postFavorite = (data) => { // 收藏
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchFavorite`,
        data: data
    })
}

export const postUnFavorite = (data) => { // 取消收藏
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchUnFavorite`,
        data: data
    })
}

export const postRenameFile = (data) => { // 重命名文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/renameFile`,
        data: data
    })
}

export const postRenameFolder = (data) => { // 重命名文件夹
    return request({
        method: 'post',
        url: base + `/cs/file/api/renameFolder`,
        data: data
    })
}

export const postRemove = (data) => { // 批量删除
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchPutDocumentInRecycleBin`,
        data: data
    })
}

export const getDetail = (data) => { // 文件/文件夹详情
    if (data.isFolder) {
        const params = {
            folderId: data.id
        }
        return request({
            method: 'post',
            url: base + `/cs/file/api/getFolderDetail`,
            data: params
        })
    } else {
        const params = {
            fileId: data.id,
            source: data.source
        }
        if (params.source === 2) {
            params.groupId = data.groupId
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/getFileDetail`,
            params: params
        })
    }
}

export const getFolder = (data) => { // 获取文件夹下的文件夹
    return request({
        method: 'get',
        url: base + `/cs/file/api/getSpaceFolderForParentId`,
        params: data
    })
}

export const postMoveFile = (data) => { // 移动文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchMoveDocument`,
        data: data
    })
}

export const postCopyFile = (data) => { // 复制文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/api/copyFile`,
        data: data
    })
}

export const getCapacity = (data) => { // 个人空间容量
    return request({
        method: 'get',
        url: base + `/cs/user/api/user/getUserSpace`,
        params: data
    })
}

export const getFolderFavorites = (data) => { // 获取收藏列表
    if (data.isFolder) {
        const params = {
            type: 3,
            order: data.order,
            folderId: data.folderId,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/getFileForParentFolder`,
            params: params
        })
    } else {
        const params = {
            order: data.order,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/indexFileListForFavorites`,
            params: params
        })
    } 
}

export const getRecycleList = (data) => { // 文件回收站
    if (data.isFolder) {
        const params = {
            folderId: data.folderId
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/getRecycleBinListForFolderId`,
            params: params
        })
    } else {
        const params = {
            order: data.order,
            pageSize: data.pageSize,
            pageNo: data.pageNo
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/indexRecycleBinList`,
            params: params
        })
    }
}

export const postRemoveFromRecycle = (data) => { // 彻底删除
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchDelDocFromRecycle`,
        data: data
    })
}

export const postRestoreFile = (data) => { // 恢复
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchRestoreDoc`,
        data: data
    })
}

export const postGroupList = (data) => { // 群组列表
    return request({
        method: 'post',
        url: base + `/cs/user/api/groups/list`,
        data: data
    })
}

export const postCreateGroup = (data) => { // 创建群组
    store.commit('updateOptCode', ['createGroupCode', '', ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/groups/create`,
        data: data
    })
}

export const getSpaceCapacity = (data) => { // 群组空间容量
    store.commit('updateOptCode', ['getGroupSpaceCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/groups/space`,
        data: data
    })
}

export const getUserGroupAuth = (data) => { // 获取用户群组权限
    return request({
        method: 'post',
        url: base + `/cs/user/api/groupUser/getUserGroupAuth`,
        data: data
    })
}

export const getGroupList = (data) => { // 获取群组文件
    if (data.isFolder) {
        const params = {
            type: 2,
            order: data.order,
            folderId: data.folderId,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType,
            groupId: data.groupId
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/getFileForParentFolder`,
            params: params
        })
    } else {
        const params = {
            order: data.order,
            pageSize: data.pageSize,
            pageNo: data.pageNo,
            fileType: data.fileType,
            groupId: data.groupId
        }
        return request({
            method: 'get',
            url: base + `/cs/file/api/indexFileListForGroup`,
            params: params
        })
    }
}

export const batchDelGroupDocument = (data) => { // 群组文件删除
    return request({
        method: 'post',
        url: base + `/cs/file/api/batchDelGroupDocument`,
        data: data
    })
}

export const postUnloadingFile = (data) => { // 转存文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/api/unloadingFile`,
        data: data
    })
}

export const postOperationList = (data) => { // 群组操作记录
    return request({
        method: 'post',
        url: base + `/cs/user/api/operation/list`,
        data: data
    })
}

export const postGroupDetail = (data) => { // 群组详情
    return request({
        method: 'post',
        url: base + `/cs/user/api/groups/detail`,
        data: data
    })
}

export const postDelGroup = (data) => { // 解散群组
    store.commit('updateOptCode', ['dissolutionGroupCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/groups/del`,
        data: data
    })
}

export const postChangeUserGroupName = (data) => { // 修改群昵称
    store.commit('updateOptCode', ['updateGroupNameCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/changeUserGroupName`,
        data: data
    })
}

export const postChangeGroupName = (data) => { // 修改群名称
    store.commit('updateOptCode', ['updateGroupNameCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/changeGroupName`,
        data: data
    })
}

export const setGroupDefaultAuth = (data) => { // 设置群组默认权限
    store.commit('updateOptCode', ['optAuthorityCode', data.groupId, ''])
    console
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/setGroupDefaultAuth`,
        data: data
    })
}

export const postUpdateRole = (data) => { // 设置用户权限
    store.commit('updateOptCode', ['optAuthorityCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/updateRole`,
        data: data
    })
}

export const postQuitGroup = (data) => { // 退出群组
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/quitGroup`,
        data: data
    })
}

export const getSearchFile = (data) => { // 文件检索
    return request({
        method: 'get',
        url: base + `/cs/file/api/searchFile`,
        params: data
    })
}

export const getSearchFileCount = (data) => { // 文件检索数量
    return request({
        method: 'get',
        url: base + `/cs/file/api/searchFileCount`,
        params: data
    })
}

export const optFileTransfer = (data) => { // 操作文件
    return request({
        method: 'post',
        url: base + `/cs/transmission/api/optFileTransfer`,
        data: data
    })
}

export const getFileTransferList = (data) => { // 下载列表
    return request({
        method: 'get',
        url: base + `/cs/transmission/api/getFileTransferList`,
        params: data
    })
}

export const checkUploadFile = (data) => { // 校验上传文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/upload/checkFile`,
        data: data
    })
}

export const uploadMergeChunk = (data) => { // 合并切片
    return request({
        method: 'post',
        url:base +  `/cs/transmission/api/upload/merge`,
        data: data
    })
}

export const uploadChunk2 = (data) => { // 上传第二步
    return request({
        method: 'post',
        url: base + `/cs/transmission/api/upload/chunk`,
        data: data
    })
}

export const uploadChunk = (data, callback) => { // 上传切片
    return request({
        method: 'post',
        url: baseUpd + `/cs/transmission/api/upload/chunk`,
        data: data,
        onUploadProgress: callback
    })
}

export const upFileDowSchedule = (data, callback) => { // 下载第二步
    return request({
        method: 'post',
        url: base + `/cs/transmission/api/download/upFileDowSchedule`,
        data: data,
        onDownloadProgress: callback
    })
}

export const downloadOne = (data) => { // 下载第一步
    return request({
        method: 'post',
        url:base +  `/cs/file/api/download/checkFile`,
        data: data
    })
}

export const downloadTwo = (data, callback) => { // 下载第二步
    return request({
        method: 'post',
        url: baseUpd + `/cs/file/api/download/getFileChunk`,
        data: data,
        onDownloadProgress: callback
    })
}

export const groupAddUser = (data) => { // 添加群组成员
    store.commit('updateOptCode', ['invitePersonnelCode', data.groupId, ''])
    return request({
        method: 'post',
        url: base + `/cs/user/api/group/addUser`,
        data: data
    })
}

export const shareFileApi = (data) => { // 分享文件
    return request({
        method: 'post',
        url: base + `/cs/file/api/shareFile?fileId=${data.fileId}`,
        data: data
    })
}