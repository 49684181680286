import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "@/router/index";
import store from "@/store";
import { checkMoaToken } from "@/api/space";

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
Vue.config.productionTip = false;
let router = null;
function render(props = {}) {
  const { container } = props;
  console.log('props:', props)
  // 路由内容作为参考，实际路由配置以项⽬为准
  // vue的路由需要在main⽂件中通过⽅法进⾏初始化,防⽌影响到主应⽤的路由
  const baseUrl = window.__POWERED_BY_QIANKUN__ ? "/main/app/app-ydwp/" : "/";
  router = new VueRouter({
    routes,
  });

  router.beforeEach((to, from, next) => {
    console.log(to, from);
     console.log(to.query);
    
    const token = to.query.token;

	console.log('token:', token);
	
    if (store.state.token) {
      next();
      store.commit("changeAsideShow", true);
    } else {
      checkMoaToken({
        token: token,
      }).then((res) => {
        if (res) {
          store.commit("changeToken", res.data.token);
          store.commit("changeSectionSize", res.data.pcSectionSize ? res.data.pcSectionSize : 1024000)
          store.commit("changeThread", res.data.pcSectionCount ? res.data.pcSectionCount : 5)
          store.commit("getUpLoadList");
          store.commit("getDownLoadList");
          next();
          store.commit("changeAsideShow", true);
        }
      });
    }
  });
  // vue启动⽅式修改
  window.ydwpInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector("#app") : "#app");
  console.log(baseUrl);
}

// 独⽴运⾏时
if (!window.__POWERED_BY_QIANKUN__) {
  render();
}

window.appRender = (props = {}) => {
  return new Promise((resolve, reject) => {
    if (!window.__POWERED_BY_QIANKUN__) {
      reject(new Error("环境错误"));
    } else {
      render(props);
      resolve(window.ydwpInstance);
    }
  });
};

// export async function bootstrap() {
//   console.log('[vue] vue app bootstraped');
// }
// export async function mount(props) {
//   console.log('[vue] props from main framework', props);
//   render(props);
// }
// export async function unmount() {
//   window.ydwpInstance.$destroy();
//   window.ydwpInstance.$el.innerHTML = '';
//   window.ydwpInstance = null;
// }