<template>
  <div id="app">
    <Toast
      v-if="this.$store.state.toastShow"
      :msg="this.$store.state.toastMsg"
      :click-msg="this.$store.state.toastClickMsg"
      :msg-click="this.$store.state.toastClick"
    ></Toast>
    <layout style="height: 100%">
      <layout-sider :width="292" v-if="this.$store.state.asideShow">
        <Aside />
      </layout-sider>
      <layout style="height: 100%">
        <layout-header
          style="height: 70px; background-color: white; padding: 0"
        >
          <header-component></header-component>
        </layout-header>
        <layout-content style="flex: 1">
          <router-view v-slot="{ Component }">
            <keep-alive>
              <component
                :is="Component"
                :key="$route.name"
                v-if="$route.meta.keepAlive"
              />
            </keep-alive>
            <component
              :is="Component"
              :key="$route.name"
              v-if="!$route.meta.keepAlive"
            />
          </router-view>
        </layout-content>
      </layout>
    </layout>
  </div>
</template>

<script>
import Aside from "@/components/Aside.vue";
import Toast from "@/components/Toast.vue";
import HeaderComponent from "@/components/Header.vue";
import { Layout } from "ant-design-vue";
export default {
  name: "App",
  data() {
    return {
      timer: null,
    };
  },
  components: {
    HeaderComponent,
    Aside,
    Toast,
    Layout,
    LayoutContent: Layout.Content,
    LayoutSider: Layout.Sider,
    LayoutHeader: Layout.Header,
  },
  mounted() {
    const _that = this;
    window.addEventListener("online", () => {
      console.log(navigator.onLine)
      _that.$store.commit("changeOnLine", navigator.onLine);
    })
    window.addEventListener("offline", () => {
      _that.$store.commit("changeOnLine", navigator.onLine);
    })
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100%;
  position: relative;
}
</style>
