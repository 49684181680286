import fs from "fs"; //需要安装 npm install fs
import crypto from "crypto"; //需要安装 npm install crypto
import SparkMD5 from "spark-md5"; //需要安装 npm install spark-md5
import path from "path"; //需要安装 npm install path
// import { ipcRenderer } from 'electron'; // 无需安装，需要electron环境内添加监听器

/**
 * 使用说明：
 * 上传文件：
 * 		1.调用getFileInfo方法，选择文件，返回文件相关信息
 * 		2.调用createChunksByFileSize方法，传入1返回的文件大小，获取每个切片的字节区间值
 * 		3.循环2返回的数组，一次调用getSliceBySection方法，获取每个切片的hash和base64
 * 下载文件：
 * 		1.调用selectLocalDir方法，选择保存文件的目录，返回目录绝对路径
 * 		2.根据服务端接口返回的文件信息，使用createChunksByFileSize方法，获取每个切片的字节区间值
 * 		3.循环2返回的字节区间数组，调用服务端接口，获取数据
 * 		4.获取到数据，调用saveSlice方法，保存切片数据
 * 		5.下载完所有切片后，调用mergeFile方法，合并所有切片
 */

/**
 * 打开选择器选择本地目录，返回目录的绝对路径
 * 需要electron环境内添加监听器
 * ipcMain.on('open-directory-dialog', function (event, p) {
	dialog.showOpenDialog({
		properties: [p],
		title: '请选择保存目录',
		buttonLabel: '选择'
	}).then(result => {
		console.log(result)
		event.sender.send('selectedItem', result.filePaths[0])
	})
});
 * @returns 
 */
export const selectLocalDir = () => {
  return new Promise((resolve) => {
    // 使用IPC通信的方式
    // open-directory-dialog 这个需要和electron的事件监听相同
    // ipcRenderer.send('open-directory-dialog', 'openDirectory');
    // selectedItem 这个需要和electron的事件监听接收路径监听相同
    // ipcRenderer.on('selectedItem', function (e, files) {
    // 	console.log(e);
    // 	resolve(files);
    // });
    // 使用remote方式
    const remote = window.require("@electron/remote");
    remote.dialog
      .showOpenDialog({
        title: "请选择文件",
        buttonLabel: "选择",
        properties: ["openDirectory"],
      })
      .then((res) => {
        resolve(res.filePaths[0]);
      });
  });
};
/**
 * 选择本地文件，并返回文件路径
 * @returns
 */
export const selectLocalFile = () => {
  return new Promise((resolve) => {
    // 使用IPC通信的方式
    // open-directory-dialog 这个需要和electron的事件监听相同
    // ipcRenderer.send('open-directory-dialog', 'openFile');
    // selectedItem 这个需要和electron的事件监听接收路径监听相同
    // ipcRenderer.on('selectedItem', function (e, files) {
    // 	console.log(e);
    // 	resolve(files);
    // });

    // 使用remote方式{properties: ['openFile', 'openDirectory', 'multiSelections']}
    const remote = window.require("@electron/remote");
    remote.dialog
      .showOpenDialog({
        title: "请选择文件",
        buttonLabel: "打开",
        properties: ["openFile"],
      })
      .then((res) => {
        resolve(res.filePaths[0]);
      });
  });
};

/**
 * 获取文件信息，文件hash，文件大小：单位，字节，文件本地路径
 * @returns
 */
export const getFileInfo = async () => {
  // 选择文件，获取文件信息
  let filePath = await selectLocalFile();
  if (!filePath) {
    return false;
  }

  let fileDetail = fs.statSync(filePath);
  if (!fileDetail) {
    return false;
  }

  const res = await getSliceBySection(filePath, 0, fileDetail.size > 1000 ? 1000 : fileDetail.size);
  let md5 = res.hash;
  return { file_size: fileDetail.size, file_hash: md5, file_path: filePath };
};

/**
 * 使用fs获取切片信息
 * @param filepath 文件路径
 * @param start 开始字节（包含）
 * @param end 结束字节（不包含）
 * @return Object base64: 切片base64字符串，hash：切片hash
 **/
export const getSliceBySection = (filepath, start, end) => {
  return new Promise((resolve) => {
    const readStream = fs.createReadStream(filepath, {
      start: start,
      end: end,
      highWaterMark: end - start,
    });

    // readStream.setEncoding('binary')

    readStream.on("data", (chunk) => {
      // binary转Base64
      let str = Buffer.from(chunk).toString("base64");
      let md5 = crypto.createHash("md5").update(chunk).digest("hex");
      resolve({ base64: str, hash: md5 });
      return;
    });
    readStream.on("end", () => {
      resolve("");
    });
  });
};

/**
 * 获取所有切片信息
 * @param file 文件对象（input type=file）
 * @param chunkSize 每个切片的大小（单位：字节）
 * @return Array [blob,blob]
 */
export const createChunks = (file, chunkSize) => {
  const result = [];
  for (let i = 0; i < file.size; i += chunkSize) {
    result.push(file.slice(i, i + chunkSize));
  }

  return result;
};

/**
 * 根据文件大小和切片大小，获取每个分片的起始字节位置
 * @param {*} fileSize 文件大小，单位：byte
 * @param {*} chunkSize 每个切片大小，单位：byte
 * @returns [{"start":"开始字节位置（包含）", "end": "结束字节位置（不包含）"}]
 */
export const createChunksByFileSize = (fileSize, chunkSize) => {
  const result = [];
  for (let i = 0; i < fileSize; i += chunkSize) {
    result.push({ start: i, end: i + chunkSize });
  }

  return result;
};

/**
 * 根据切片blob 获取切片base64
 * @param blob 切片Blob
 * @return String 切片base64字符串
 */
export const sliceBase64ByBlob = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let bytes = e.target.result; // 读取到字节数组
      bytes = bytes.replace("data:application/octet-stream;base64,", "");

      resolve(bytes);
      return; // 读取完成
    };

    console.log("开始读取切片数据流");

    reader.readAsDataURL(blob);
  });
};

/**
 * 根据切片blob 获取切片hash
 * @param blob 切片Blob
 * @return String 切片hash
 */
export const sliceHashByBlob = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const spark = new SparkMD5();
    reader.onload = (e) => {
      let bytes = e.target.result; // 读取到字节数组
      spark.append(bytes);

      resolve(spark.end());
      return; // 读取完成
    };

    reader.readAsBinaryString(blob);
  });
};

/**
 * 根据切片获取文件Hash（前端使用，Blob）
 * @param {*} chunks 文件切片数组
 * @returns String 文件hash
 */
export const fileHash = (chunks) => {
  return new Promise((resolve) => {
    const spark = new SparkMD5();
    function _read(i) {
      if (i >= chunks.length) {
        resolve(spark.end());
        return; // 读取完成
      }

      const blob = chunks[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        let bytes = e.target.result; // 读取到二进制流
        spark.appendBinary(bytes);
        _read(i + 1);
      };
      reader.readAsBinaryString(blob);
    }

    _read(0);
  });
};

/**
 * 合并指定路径下所有切片文件
 * @param {*} catalogue 切片存放路径
 */
export const mergeFile = async (catalogue, fileHash, sumFileName) => {
  console.log("fileHash: ", fileHash);
  return new Promise((resolve, reject) => {
    try {
      var readDirs = fs.readdirSync(path.resolve(catalogue, "." + fileHash));
      let readDir = readDirs.filter((value) => {
        if (!/^\..*/.test(value)) {
          return true;
        }
      });
      if (readDir.length <= 0) {
        resolve({ status: false, msg: "文件夹内没有数据" });
        return;
      }
      readDir.sort((a, b) => a.split("-")[0] - b.split("-")[0]);
      // 采用Stream方式合并
      let newFilePath = path.resolve(catalogue, sumFileName);
      let targetStream = fs.createWriteStream(newFilePath);
      console.log('开始读取文件')
      const readStream = async (chunkArray) => {
        let fileName = chunkArray.shift();
        // 忽略隐藏文件
        if (!/^\..*/.test(fileName)) {
          let slicePath = path.resolve(catalogue, "." + fileHash, fileName);
          let originStream = fs.createReadStream(slicePath);

          originStream.pipe(targetStream, { end: false });
          originStream.on("end", async () => {
            // 删除切片文件
            fs.unlinkSync(slicePath);
            if (chunkArray.length > 0) {
              console.log('开始合并切片')
              readStream(chunkArray);
            } else {
              console.log('关闭读取文件')
              targetStream.close()
              resolve({ status: true, msg: "success" });
              var timeout = setTimeout(() => {
                clearTimeout(timeout)
                fs.rmdirSync(path.resolve(catalogue, "." + fileHash));
              }, 300)
              return;
            }
          });
        } else {
          if (chunkArray.length > 0) {
            console.log('开始合并切片')
            readStream(chunkArray);
          } else {
            console.log('关闭读取文件')
            targetStream.close()
            resolve({ status: true, msg: "success" });
            var timeout = setTimeout(() => {
              clearTimeout(timeout)
              fs.rmdirSync(path.resolve(catalogue, "." + fileHash));
            }, 300)
            return;
          }
        }
      };
      readStream(readDir);
    } catch (e) {
      reject({ status: false, msg: e });
    }
  });
};

/**
 * 保存切片到磁盘
 * @param {*} data
 * @param String data.file_name 切片名称
 * @param String data.slice_save_path 切片保存目录
 * @param String data.file_hash 文件hash
 * @param String data.file_slice_hash 切片hash
 * @param String data.slice_stream 切片base64
 * @returns bool true成功，false失败
 */
export const saveSlice = (data) => {
  return new Promise((resolve) => {
    let {
      file_name,
      slice_save_path,
      file_hash,
      slice_stream,
    } = data;
    // 检查文件是否存在，存在先删除，重新创建
    slice_save_path = path.resolve(slice_save_path, "." + file_hash);
    let filepath = path.resolve(slice_save_path, file_name);
    var sliceBuffer = Buffer.from(slice_stream, "base64");
    console.log("toBuffer", sliceBuffer);
    fs.access(slice_save_path, fs.constants.F_OK, (err) => {
      if (err) {
        fs.mkdir(
          slice_save_path,
          {
            recursive: true,
          },
          (err) => {
            if (err) throw err;
            fs.writeFileSync(filepath, sliceBuffer, { mode: "0777" });
            resolve(true);
            return;
          }
        );
      } else {
        fs.writeFileSync(filepath, sliceBuffer, { mode: "0777" });
        resolve(true);
        return;
      }
    });
  });
};

/**
 * 验证文件hash（切片使用）
 * @param {*} filepath 文件路径
 * @param {*} file_hash 文件hash
 * @returns
 */
export const checkFileHash = () => {
  return true;
};

/**
 * 计算大文件hash
 * @param {*} localFilePath
 * @param {*} callback
 */
export const getFileMD5 = (localFilePath) => {
  return new Promise((resolve) => {
    //创建md5对象（基于SparkMD5）
    var spark = new SparkMD5.ArrayBuffer();

    let rs = fs.createReadStream(localFilePath, {
      autoClose: true,
    });
    rs.on("data", (chunk) => {
      // chunk是Buffer
      spark.append(chunk);
    });
    rs.on("end", () => {
      resolve(spark.end());
      return;
    });
  });
};

/**
 *
 * @returns 获取mac地址
 */
export const getPcMacAddress = () => {
  let networkInterfaces = require("os").networkInterfaces();

  let macAddress = "";
  for (var i in networkInterfaces) {
    for (var j in networkInterfaces[i]) {
      if (
        networkInterfaces[i][j]["family"] === "IPv4" &&
        networkInterfaces[i][j]["mac"] !== "00:00:00:00:00:00" &&
        networkInterfaces[i][j]["address"] !== "127.0.0.1"
      ) {
        macAddress = networkInterfaces[i][j]["mac"];
      }
    }
  }

  return macAddress;
};
