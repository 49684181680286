<template>
    <div class="empty">
        <img src="@/assets/image/empty.png" alt="">
        <span>暂无内容</span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'empty-component',
    mounted() {

    }
  }
  </script>
  
  <style scoped lang="less">
    .empty {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #999;
        font-size: 14px;

        img {
            width: 35%;
        }
    }
  </style>
  