import Vue from "vue";
import Vuex from "vuex";
import downloadMutations from "./download";
import uploadMutations from "./upload";
Vue.use(Vuex);

const store = new Vuex.Store({
  // 配置项
  state() {
    return {
      downloadList: [],
      downloadPlayList: [],
      downloadWaitList: [],

      uploadList: [],
      uploadPlayList: [],
      uploadWaitList: [],
      cancelUpload: [],

      listUpdate: false,
      syncNum: 5,

      thread: 5,
      onLine: true,
      transferLine: true,
      title: "",
      titleTip: "",
      token: "",
      searchValue: "",
      searchType: 1,
      spaceSizeShow: false,
      totalSpace: 0,
      residueSpace: 0,
      asideShow: false,
      toastShow: false,
      toastTimeout: null,
      toastMsg: "",
      toastClickMsg: "",
      toastClick: () => {},
      optCode: "",
      groupId: "",
      fType: "",
      userList: [],
      sectionSize: 1024 * 1000,
      resultShow: false,
      baseUrl: window.__POWERED_BY_QIANKUN__ ? "/main/app/app-ydwp" : "",
    };
  },
  mutations: {
    ...downloadMutations,
    ...uploadMutations,
    changeListUpdate(state) {
      state.listUpdate = !state.listUpdate;
    },
    changeThread(state, val) {
      state.thread = val;
    },
    changeSectionSize(state, val) {
      state.sectionSize = val;
    },
    reload() {
      if (this.state.onLine) {
        this.commit("changeTransferLine", true);
      }
    },
    changeTransferLine(state, val) {
      state.transferLine = val;
      if (val) {
        this.commit("startDownload");
        // this.commit("startUpload");
      }
    },
    changeOnLine(state, val) {
      state.onLine = val;
      this.commit("changeTransferLine", val);
    },
    changeUserList(state, val) {
      state.userList = val;
    },
    updateOptCode(state, val) {
      state.optCode = val[0];
      state.groupId = val[1];
      state.fType = val[2];
    },
    clearOptCode(state) {
      state.optCode = "";
      state.groupId = "";
      state.fType = "";
    },
    showToast(state, val) {
      state.toastShow = true;
      state.toastMsg = val[0];
      state.toastClickMsg = val[1];
      state.toastClick = val[2];
      clearTimeout(state.toastTimeout);
      state.toastTimeout = setTimeout(() => {
        state.toastShow = false;
        state.toastMsg = "";
        state.toastClickMsg = "";
        state.toastClick = () => {};
        clearTimeout(state.toastTimeout);
      }, 2000);
    },
    changeToken(state, val) {
      state.token = val;
    },
    changeAsideShow(state, val) {
      state.asideShow = val;
    },
    changeTitle(state, val) {
      state.title = val[0];
      state.titleTip = val[1];
    },
    changeSearchValue(state, val) {
      state.searchValue = val;
    },
    changeSearchType(state, val) {
      state.searchType = val;
    },
    changeSpaceSize(state, space) {
      state.spaceSizeShow = space[0];
      state.totalSpace = space[1];
      state.residueSpace = space[2];
    },
    changeResultShow(state, resultShow) {
      state.resultShow = resultShow;
    },
  },
});

export default store;
