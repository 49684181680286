<template>
  <div class="aside">
    <div class="aside-search">
      <div class="aside-input">
        <img src="@/assets/image/img-search.png" alt="" /><input
          type="text"
          v-model="value"
          placeholder="搜索我的网盘文件"
          @keyup.enter="inputChange"
          @input="change"
        /><img @click="clear" src="@/assets/image/img-close.png" alt="" />
      </div>
    </div>
    <div class="aside-result" v-if="this.$store.state.resultShow">
      <div @click="toSearch(0)" v-if="num0 > 0">
        全部<template v-if="value"
          >包含<span>{{ value }}</span
          >的</template
        >文件（{{ num0 }}）
      </div>
      <div @click="toSearch(1)" v-if="num1 > 0">
        个人空间<template v-if="value"
          >包含<span>{{ value }}</span
          >的</template
        >文件（{{ num1 }}）
      </div>
      <div @click="toSearch(2)" v-if="num2 > 0">
        群组<template v-if="value"
          >包含<span>{{ value }}</span
          >的</template
        >文件（{{ num2 }}）
      </div>
      <div @click="toSearch(3)" v-if="num3 > 0">
        收藏管理<template v-if="value"
          >包含<span>{{ value }}</span
          >的</template
        >文件（{{ num3 }}）
        <div style="padding: 0;font-size: 12px;color: #999;">收藏文件来自个人空间或者群组，不计入总数</div>
      </div>
        <Empty v-if="num0 === 0" />
    </div>
    <div class="aside-menu" v-else>
      <div
        @click="toPage('/space')"
        :class="this.$route.meta.page === 1 ? 'active' : ''"
      >
        <img src="@/assets/image/space-icon.png" alt="" />个人空间
      </div>
      <div
        @click="toPage('/group')"
        :class="this.$route.meta.page === 2 ? 'active' : ''"
      >
        <img src="@/assets/image/group-icon.png" alt="" />群组
      </div>
      <div
        @click="toPage('/collect')"
        :class="this.$route.meta.page === 3 ? 'active' : ''"
      >
        <img src="@/assets/image/collect-icon.png" alt="" />收藏管理
      </div>
      <div
        @click="toPage('/recycle')"
        :class="this.$route.meta.page === 4 ? 'active' : ''"
      >
        <img src="@/assets/image/recycle-icon.png" alt="" />文件回收站
      </div>
    </div>
    <div
      class="aside-space"
      v-if="!this.$store.state.resultShow && this.$store.state.spaceSizeShow"
    >
      <div class="progress">
        <div
          class="bar"
          :style="
            'width:' +
            (this.$store.state.residueSpace / this.$store.state.totalSpace) *
              100 +
            '%'
          "
        ></div>
      </div>
      <div class="space-size">
        {{
          this.$store.state.residueSpace +
          "GB/" +
          this.$store.state.totalSpace +
          "GB"
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { getSearchFileCount } from "@/api/space";
import Empty from "./Empty.vue";
export default {
  components: {
    Empty
  },
  name: "aside-component",
  data() {
    return {
      value: "",
      timeout: null,
      num1: 0,
      num2: 0,
      num3: 0,
      num0: 0
    };
  },
  watch: {
    "$store.state.searchValue": {
      handler(val) {
        this.value = val;
      },
    },
  },
  mounted() {},
  methods: {
    inputChange: function () {
      if (this.num0 === 0) {
        this.$store.commit("showToast", ["未查询到内容", "", () => {}]);
      }
    },
    change: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (this.$route.path != this.$store.state.baseUrl + "/search") {
          this.$store.commit("changeSearchType", 0);
          this.$router.push(this.$store.state.baseUrl + "/search");
        } else {
          if (!this.value) {
            this.$router.go(-1);
          }
        }
        getSearchFileCount({ keyword: this.value }).then((res) => {
          if (res) {
            console.log(res);
            this.num0 = res.data.allCount;
            this.num1 = res.data.mySpaceCount;
            this.num2 = res.data.groupCount;
            this.num3 = res.data.favoriteCount;
          }
        });
        
        if (!this.value) {
          this.$store.commit("changeResultShow", false);
        } else {
          this.$store.commit("changeResultShow", true);
        }
        this.$store.commit("changeSearchValue", this.value);
      }, 300);
    },
    toSearch: function (type) {
      this.$store.commit("changeSearchType", type);
      this.$store.commit("changeListUpdate");
    },
    clear: function () {
      this.value = "";
      this.$store.commit("changeSearchValue", this.value);
      this.change();
    },
    toPage(path) {
      this.$router.push(this.$store.state.baseUrl + path);
    },
  },
};
</script>

<style scoped lang="less">
.aside {
  width: 292px;
  height: 100vh;
  background-color: white;
  position: relative;
  border-right: 1px solid #f5f5f5;
  box-sizing: border-box;

  .aside-result {
    font-size: 14px;
    div {
      padding: 10px 16px;
      // height: 50px;
      // line-height: 50px;
      text-align: left;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      span {
        color: #4a90e5;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-all;
      }
    }
  }

  .aside-search {
    padding: 20px 16px;
    .aside-input {
      display: flex;
      align-items: center;
      padding: 8px;
      background-color: #f5f5f5;
      color: #333;

      img {
        width: 12px;
        margin: 0 4px;
      }

      input {
        width: 100%;
        background-color: transparent;
        border: 0 none;
        outline: none;
        font-size: 12px;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px #022068 inset;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }

  .aside-menu {
    div {
      display: flex;
      align-items: center;
      vertical-align: middle;
      height: 56px;
      line-height: 56px;
      border-left: 3px solid white;
      background-color: white;
      font-size: 14px;
      cursor: pointer;
    }

    img {
      width: 16px;
      height: 16px;
      margin: 0 18px;
    }
    .active {
      border-left: 3px solid #4a90e5;
      background-color: #f5f5f5;
    }
  }

  .aside-space {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px;
    .progress {
      background-color: #f5f5f5;
      height: 8px;
      border-radius: 6px;
    }
    .bar {
      background-color: #4a90e5;
      border-radius: 6px;
      height: 8px;
    }

    .space-size {
      margin-top: 8px;
      color: #999;
      font-size: 12px;
      text-align: center;
    }
  }
}
</style>
