<template>
  <div class="toast-box">
    <div class="toast">
      {{ msg
      }}<span v-if="clickMsg != ''" @click="msgClick">{{
        clickMsg
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "toast-component",
  props: ['msg', 'msgClick', 'clickMsg'],
};
</script>

<style scoped lang="less">
.toast-box {
    z-index: 1002;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    text-align: center;
}
.toast {
    display: inline-block;
    color: #333;
    font-size: 16px;
    padding: 15px 20px;
    background-color: #fff;
    margin-top: 100px;
    border-radius: 8px;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.17);
    span {
        color: #4A90E5;
        cursor: pointer;
    }
}
</style>
