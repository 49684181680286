<template>
    <div class="wp-header">{{this.$store.state.title}} <span>{{this.$store.state.titleTip}}</span></div>
  </template>
  
  <script>
  export default {
    name: 'header-component',
    mounted() {

    }
  }
  </script>
  
  <style scoped lang="less">
  .wp-header {
    width: 100%;
    padding: 24px;
    background-color: white;
    height: 70px;
    line-height: 22px;
    box-sizing: border-box;
    font-size: 16px;
    color: #000000;
    border-bottom: 1px solid #F5F5F5;
    span {
      font-size: 12px;
      color: #999999;
      line-height: 16px;
      margin-left: 12px;
    }
  }
  </style>
  