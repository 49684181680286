import axios from 'axios'
import store from "@/store"
class Request {
    // axios 实例
    instance

    constructor() {
        this.instance = axios.create({
            baseURL: '',
            timeout: 0,
        })

        this.instance.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = store.state.token
                console.log(store.state)
                if (store.state.optCode) {
                    config.headers['optCode'] = store.state.optCode
                }
                if (store.state.groupId) {
                    config.headers['groupId'] = store.state.groupId
                }
                if (store.state.fType) {
                    config.headers['fType'] = store.state.fType
                }
                config.headers['source'] = 'pc2';

                store.commit('clearOptCode');
                return config
            },
            (err) => err
        )

        this.instance.interceptors.response.use(
            (res) => {
                if (res.data.code === 0) {
                    return res.data || true
                } else {
                    this.error(res.data.msg)
                    return false
                }
            },
            (err) => {
                this.error(err?.data?.message)
                return false
            }
        )
    }

    error(msg) {
        if (msg) {
            store.commit("showToast", [msg, "", () => {}]);
            return
        }
    }
}

export default new Request().instance
